import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VerordenComponent } from './Orden/verorden/verorden.component';
import {OrdenService} from './Orden/orden.service';
import { InicioComponent } from './inicio/inicio.component';
import { VistaComponent } from './vista/vista.component';
import { AccesosComponent } from './accesos/accesos.component';

@NgModule({
  declarations: [
    AppComponent,
    VerordenComponent,
    InicioComponent,
    VistaComponent,
    AccesosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [AppComponent,OrdenService],
  bootstrap: [AppComponent]
})
export class AppModule { }
