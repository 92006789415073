import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import {OrdenService} from 'src/app/Orden/orden.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {
  statusPeticion:number = -1;// -1 esta cargando
  Mensaje0:String="Obteniendo ordenes";
  Mensaje:String="Por favor espere...";
  //
  FolioOrden:number;
  NumeroOrden:number;
  datosOrden = [];
  DsReparacion:String = "";
  constructor(private Orden:OrdenService,private appcom:AppComponent,private router:Router) { 
    this.appcom.Ok = false;
  }
  ngOnInit() {
    document.getElementById("inputFolioOrden").focus();
  }
  enter(event){
    this.router.navigate(['inicio']);
  }
  getDatosOrden(){
    //console.log("Folio:" +this.FolioOrden);
    //console.log("NumeroOrden:" +this.NumeroOrden);
    if(this.FolioOrden>0 && this.NumeroOrden>0){
    this.statusPeticion = -1;// -1 esta cargando
    this.Mensaje0="Buscando orden";
    this.Mensaje="Por favor espere...";
    var element = document.getElementById("vermodalOk");
    element.click();
    this.Orden.getOrden("get",(this.FolioOrden.toString().trim()),(this.NumeroOrden.toString().trim())).subscribe(
      (response)=>{
       // console.log(response);
        if(response.success == 1){
          this.datosOrden = response.Orden;
          this.appcom.DatosOrden = response.Orden;
          this.appcom.Folio = this.FolioOrden+"";
          this.appcom.NumeroOrden = this.NumeroOrden+"";
          this.statusPeticion = 1;// -1 esta cargando
          this.Mensaje0="Exito!";
          this.Mensaje="Orden encontrada.";
          this.appcom.Ok = true;   
          document.getElementById("btAceptarModal").focus();       
          setTimeout(() => 
          {
            var element = document.getElementById("vermodalOk");
            element.click();
            this.router.navigate(['orden']);
          },
          1000);
        }else if(response.success == 0){
          this.appcom.Ok = false;
          this.statusPeticion = 0;// -1 esta cargando
          this.Mensaje0="Ooops!";
          this.Mensaje = response.message;
          this.appcom.DatosOrden = [];
          document.getElementById("btAceptarModal").focus();
        }
      },(error)=>{
        this.statusPeticion = 0;// -1 esta cargando
        this.Mensaje0="Ooops!";
        this.Mensaje = "Algo salio mal, reintente de nuevo por favor.";
      }    
    )
    }else{
      this.statusPeticion = 0;// -1 esta cargando
      this.Mensaje0="Ooops!";
      this.Mensaje = "No deje campos vacios, reintente de nuevo por favor.";
      var element = document.getElementById("vermodalOk");
      element.click();
    }
  }
  enterBuscaOrden(event) {
    if (event.key === "Enter") {
      if(this.FolioOrden>0 && this.NumeroOrden>0){
        this.getDatosOrden();
      }
   }
   }
}
