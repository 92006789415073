import { NgModule } from '@angular/core';
import {AppComponent} from './app.component';
import { Routes, RouterModule } from '@angular/router';
import { VerordenComponent } from './Orden/verorden/verorden.component';
import { InicioComponent } from './inicio/inicio.component';
import { VistaComponent } from './vista/vista.component';

const routes: Routes = [ {
  path:'app',
  component:AppComponent
},
{
  path:'inicio',
  component:InicioComponent
},
{
  path:'orden',
  component:VerordenComponent
},
{
path:'vista',
component:VistaComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
