import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import {OrdenService} from 'src/app/Orden/orden.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-verorden',
  templateUrl: './verorden.component.html',
  styleUrls: ['./verorden.component.css']
})
export class VerordenComponent implements OnInit {
Orden = [];
ComentarioClienteNew:String = "";

statusPeticion:number = -1;// -1 esta cargando
Mensaje0:String="Obteniendo ordenes";
Mensaje:String="Por favor espere...";
//
  constructor(private appcom:AppComponent,private OrdenSer:OrdenService,private router:Router) {
    if(this.appcom.Ok == false){
      this.router.navigate(['inicio']);
    }else{
      this.appcom.Ok = false;
    }
   }

  ngOnInit() {
    this.Orden = this.appcom.DatosOrden;
  }
  ModalVerDiagnostico(Diagnostico:String){
    this.statusPeticion = 1;
    this.Mensaje0 = "Diagnóstico:";
    this.Mensaje = Diagnostico+" (Por favor responda/confirme en la caja de comentarios.)";
    var element = document.getElementById("vermodalOk");
    element.click();
  }
  getDatosOrden(){
    this.OrdenSer.getOrden("get",(this.appcom.Folio+""),(this.appcom.NumeroOrden+"")).subscribe(
      (response)=>{
      //  console.log(response);
        if(response.success == 1){
          this.Orden = response.Orden;
          this.appcom.DatosOrden = response.Orden;
        }else if(response.success == 0){
        }
      },(error)=>{
      }    
    )
    
  }
  newComentario(){
    if(this.ComentarioClienteNew.length>0){
      this.statusPeticion = -1;
      this.Mensaje0 = "Agregando comentario.";
      this.Mensaje = "Por favor espere...";
      var element = document.getElementById("vermodalOk");
      element.click();
      this.OrdenSer.setComentarioCliente("setcomen",this.appcom.Folio,this.appcom.NumeroOrden,this.ComentarioClienteNew.toUpperCase()).subscribe(
        (response)=>{
      //   console.log(response);
          if(response.success == 1){
            this.statusPeticion = 1;
            this.Mensaje0 = "Exito!";
            this.Mensaje = "Comentario agregado correctamente.";
            this.getDatosOrden();
          }else{
            this.statusPeticion = 0;
            this.Mensaje0 = "Ooops!";
            this.Mensaje = response.message;
          }
        },(error)=>{
          this.statusPeticion = 0;
          this.Mensaje0 = "Ooops!";
          this.Mensaje ="Algo salio mal, reintente por favor.";
        }
      )
    }
  }

}
