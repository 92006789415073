import { Injectable } from '@angular/core';
import {HttpClient,HttpErrorResponse,HttpHeaders} from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, interval, pipe } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class OrdenService {
  urlOrden = "https://serviciopineda.com/clientes/bd/ordencliente.php";
  constructor(private http: HttpClient) { }

  getOrden(Accion:String,Folio:String,Orden:String):Observable<any>{
    let parametros;
    parametros = ("Accion="+Accion+"&&"+"Folio="+Folio+"&&"+"Orden="+Orden);    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',
        "method": "post"
      })};
    return this.http.
    post(this.urlOrden,parametros,httpOptions).
    pipe(map(Response => Response,catchError(this.handleError)));
}    
setComentarioCliente(Accion:String,Folio:String,Orden:String,ComentarioCliente:String):Observable<any>{
  let parametros;
  parametros = ("Accion="+Accion+"&&"+"Folio="+Folio+"&&"+"Orden="+Orden+"&&"+"ComentarioCliente="+ComentarioCliente);    
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/x-www-form-urlencoded',
      "method": "post"
    })};
  return this.http.
  post(this.urlOrden,parametros,httpOptions).
  pipe(map(Response => Response,catchError(this.handleError)));
}    
private handleError(error: HttpErrorResponse) {
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
  }
  // return an observable with a user-facing error message
  return(
    'Something bad happened; please try again later.');
};
}
